import { useUrlSearchParams } from '@vueuse/core'

export type Title =
  | 'Shift News - The Latest News Sports, and Weather'
  | 'OneNews.com - The Latest News Sports, and Weather'
  | 'New Tab'

export default function useTabTitle(): Ref<Title | undefined> {
  return useState<Title | undefined>('title', () => {
    const queryParams = useUrlSearchParams('history')
    if (isShift()) {
      return 'Shift News - The Latest News Sports, and Weather'
    }

    if (
      typeof queryParams?.headerWithLogo === 'string' &&
      queryParams?.headerWithLogo.toLowerCase() === 'false'
    ) {
      return 'New Tab'
    }

    return 'OneNews.com - The Latest News Sports, and Weather'
  })
}
